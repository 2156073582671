import {
  Anchor,
  Box,
  Button,
  Collapsible,
  Footer,
  Heading,
  Image,
  Grommet,
  Layer,
  ResponsiveContext,
  Text,
} from 'grommet';
import {
  Actions,
  Calculator,
  CaretRightFill,
  FormClose,
  Grow,
  Group,
  Notification,
  Optimize,
  Robot,
} from 'grommet-icons';
import React, { useState } from 'react';
import './App.css';

const theme = {
  global: {
    colors: {
      // hues
      // https://coolors.co/31357e-614aa8-ffd277-ff8977-57c8a6
      brand1: '#31357E', // Cosmic Cobalt
      brand2: '#614AA8', // Plump Purple
      accent: '#FFD277', // Orange Yellow Crayola
      alert: '#FF8977', // Salmon
      ok: '#57C8A6', // Ocean Green

      // mids
      // https://coolors.co/d4d5f4-e2ddf5-f9f4ef-f9ebef-e2f2f4
      mbrand1: '#D4D5F5', // Lavender Web
      mbrand2: '#E2DDF5', // Lavender Web
      maccent: '#F9F4EF', // Isabelline
      malert: '#F9EBEF', // Lavendar Blush
      mok: '#E2F2F4', // Azure X11 Web Color

      // whites
      // https://coolors.co/d4d5f4-e2ddf5-f9f4ef-f9ebef-e2f2f4
      wbrand1: '#F3F3FC', // Magnolia
      wbrand2: '#F7F6FC', // Ghost White
      waccent: '#FDFCFB', // White
      walert: '#FDFAFB', // White
      wok: '#F7FBFC', // Cultured
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
  },
};

const AppBar = (props) => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    background="brand1"
    pad={{ left: 'medium', right: 'small', vertical: 'small' }}
    elevation="large"
    style={{ zIndex: '1' }}
    {...props}
  ></Box>
);

const AppBody = (props) => (
  <Box align="center" justify="center" direction="column" background="brand1">
    <Box
      className="hero-image"
      background="linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('bluemarble.jpeg')"
      width="100%"
    >
      <section data-index="1">
        <Box>
          <h2>Re-animate that clunky spreadsheet.</h2>
          <p>
            Transform your model into a living document that grows and changes
            as quickly as you do.
          </p>
          <Box direction="row">
            <Button
              color="ok"
              icon={<Grow color="ok" />}
              label="Start Modeling"
            />
          </Box>
        </Box>
      </section>
    </Box>
    <section data-index="2">
      <Box align="top" justify="center" direction="row">
        <Box
          align="center"
          width="40%"
          pad={{ left: 'medium', right: 'medium', vertical: 'small' }}
        >
          <Actions size="xlarge" color="accent" />
          <h2 align="center">Shine light on your uncertainty</h2>
          <p>You know your risks; we'll tell you what to tackle first.</p>
        </Box>
        <Box
          align="center"
          width="40%"
          pad={{ left: 'medium', right: 'medium', vertical: 'small' }}
        >
          <Calculator size="xlarge" color="accent" />
          <h2 align="center">Data-driven consensus</h2>
          <p>You built the best team; we'll help them commit.</p>
        </Box>
      </Box>
    </section>
    <Box
      className="hero-image"
      background="linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('milky.jpeg')"
      width="100%"
    >
      <section data-index="3">
        <Box>
          <h2>Import from Excel or Google Sheets.</h2>
          <p>Get immediate insights with a single-click import.</p>
          <Box direction="row">
            <Button
              color="ok"
              icon={<CaretRightFill color="ok" />}
              label="Get Started"
            />
          </Box>
        </Box>
      </section>
    </Box>
    <section data-index="4">
      <Box direction="row" align="top" justify="center">
        <Box
          align="center"
          width="30%"
          pad={{ left: 'medium', right: 'medium', vertical: 'small' }}
        >
          <Optimize size="large" color="accent" />
          <p>
            An easy-to-use modeling interface: simple enough to start today,
            powerful enough to carry you to the next level.
          </p>
        </Box>
        <Box
          align="center"
          width="30%"
          pad={{ left: 'medium', right: 'medium', vertical: 'small' }}
        >
          <Robot size="large" color="accent" />
          <p>
            Automatic sensitivity analysis: you'll see where to direct your
            focus.
          </p>
        </Box>
        <Box
          align="center"
          width="30%"
          pad={{ left: 'medium', right: 'medium', vertical: 'small' }}
        >
          <Group size="large" color="accent" />
          <p>Track individual effects down to the bottom line.</p>
        </Box>
      </Box>
    </section>
  </Box>
);
//
//<Button
//  icon={<Notification />}
//  onClick={() => setShowSidebar(!showSidebar)}
///>

const App = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  return (
    <Grommet theme={theme} full>
      <ResponsiveContext.Consumer>
        {(size) => (
          <Box flex className="scroll-continer">
            <AppBar>
              <Heading level="2" margin="none">
                <Button>Miter Box</Button>
              </Heading>
              <Button color="ok" label="Log in" />
            </AppBar>
            <Box direction="row" flex overflow={{ horizontal: 'hidden' }}>
              <AppBody />
              {!showSidebar || size !== 'small' ? (
                <Collapsible direction="horizontal" open={showSidebar}>
                  <Box
                    flex
                    width="medium"
                    background="wbrand1"
                    elevation="small"
                    align="center"
                  >
                    sidebar
                  </Box>
                </Collapsible>
              ) : (
                <Layer>
                  <Box
                    background="wbrand1"
                    tag="header"
                    justify="end"
                    align="center"
                    direction="row"
                  >
                    <Button
                      icon={<FormClose />}
                      onClick={() => setShowSidebar(false)}
                    />
                  </Box>
                  <Box
                    fill
                    background="wbrand1"
                    align="center"
                    justify="center"
                  >
                    sidebar
                  </Box>
                </Layer>
              )}
            </Box>
            <Footer background="brand2" pad="medium">
              <Anchor color="alert" label="Contact" />
              <Anchor color="alert" label="FAQ" />
              <Anchor color="alert" label="About" />
            </Footer>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    </Grommet>
  );
};

export default App;
